import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20241128-Rate-Sheet.pdf";
const date = "28.11.2024";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
      <h1 className="page-title">Changes to our mortgage products</h1>

      <br />
      <p>With effect from Thursday 28 November 2024 we will be making the following changes to our range:</p>
      <p><strong>Summary of changes</strong></p>
      <p>All of our fixed rate end dates will be extended as below:</p>
      <ul>
        <li>2 Year Fixed &ndash; 28 February 2027</li>
        <li>3 Year Fixed &ndash; 28 February 2028</li>
        <li>5 Year Fixed &ndash; 28 February 2030</li>
        <li>10 Year Fixed &ndash; 28 February 2035</li>
      </ul>
      <p>There are no changes to any of our interest rates.</p>
      <p>An updated mortgage rate sheet will be published on Thursday 28 November 2024 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
